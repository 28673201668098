import React from 'react';

export const PRIMARY_COLOR = {
    '50': '#e1edf7',
    '100': '#b5d2eb',
    '200': '#84b4de',
    '300': '#5296d0',
    '400': '#2d80c6',
    '500': '#0869bc',
    '600': '#0761b6',
    '700': '#0656ad',
    '800': '#044ca5',
    '900': '#023b97',
    'A100': '#c2d5ff',
    'A200': '#8fb2ff',
    'A400': '#5c8fff',
    'A700': '#427dff',
};

export const SECONDARY_COLOR = {
    '50': '#ffe0e1',
    '100': '#feb3b3',
    '200': '#fe8081',
    '300': '#fd4d4e',
    '400': '#fc2628',
    '500': '#fc0002',
    '600': '#fc0002',
    '700': '#fb0001',
    '800': '#fb0001',
    '900': '#fa0001',
    'A100': '#ffffff',
    'A200': '#ffeded',
//    'A400': '#ffbaba',
    'A400': '#fc0002',  // Modification manuelle car le A400 original (utilisé par les liens) est trop clair
    'A700': '#ffa1a1',
};

export const GOOGLE_RECAPTCHA_SITE_KEY = __GOOGLE_RECAPTCHA_SITE_KEY__;
export const GOOGLE_TAG_MANAGER_ARGS = {
    gtmId: __GOOGLE_TAG_MANAGER_ID__,
};
export const NEWSLETTER_SERVER_URL = __NEWSLETTER_SERVER_URL__;



/////////////////////////////////////////////////////////
//                                                     //
//          Configuration OpenStreetMap (OSM)          //
//                                                     //
/////////////////////////////////////////////////////////

export const OSM_MAP_NAME = "OpenStreetMap_France";

export const OSM_MARKER_ICON_RETINA_URL = "/static/img/leaflet/dist/images/marker-icon-2x.png";
export const OSM_MARKER_ICON_URL = "/static/img/leaflet/dist/images/marker-icon.png";
export const OSM_MARKER_SHADOW_URL = "/static/img/leaflet/dist/images/marker-shadow.png";



//////////////////////////////////////////////////////
//                                                  //
//          Bouton d'action flottant (FAB)          //
//                                                  //
//////////////////////////////////////////////////////

// Icones utilisées
import MenuBookIcon from '@material-ui/icons/MenuBook';
import SearchIcon from '@material-ui/icons/Search';
import EventIcon from '@material-ui/icons/Event';

// Liste des actions (= boutons) contenues dans le "FAB"
export const FAB_ACTIONS = [
    { icon: <EventIcon color="primary" />, name: 'Agenda', url: "/vivre-a-pars-les-romilly/agenda/" },
    { icon: <MenuBookIcon color="primary" />, name: 'Ressource documentaire', url: "/ressource-documentaire/" },
    { icon: <SearchIcon color="primary" />, name: 'Rechercher', url: "/rechercher/" },
];



/////////////////////////////////////////////////////
//                                                 //
//          Icones utilisées dans le menu          //
//                                                 //
/////////////////////////////////////////////////////

export const MENU_ICON_MAPPING = {
}
